import axios from 'axios';

/**
 * Login
 *
 * @param {Object} payload
 *
 * @return {Promise}
 */
export const Login = payload => axios.post('mobile/login', payload);

/**
 * Register
 *
 * @param {Object} payload
 *
 * @return {Promise}
 */
export const Register = payload => axios.post('mobile/register', payload);

/**
 * Login
 *
 * @param {Object} payload
 *
 * @return {Promise}
 */
export const Logout = payload => axios.post('mobile/logout', payload);

/**
 * Update
 *
 * @param {Object} payload
 *
 * @return {Promise}
 */
export const Update = payload => axios.patch('mobile/user', payload);

/**
 * Delete
 *
 * @return {Promise}
 */
export const Delete = () => axios.delete('mobile/user');

/**
 * Get profile photo
 *
 * @param {String} photoHash
 * @param {Object} params
 *
 * @return {Promise}
 */
export const GetAvatar = (hash, params) => axios.get(`mobile/media/${hash}`, { responseType: 'arraybuffer', params });

/**
 * Update Avatar
 *
 * @param {Object} payload
 *
 * @return {Promise}
 */
export const UpdateAvatar = payload => {
    const formData = new FormData();
    formData.append('avatar', payload.blob);
    return axios.post('mobile/user/avatar', formData)
};

/**
 * Get user
 *
 * @param {Object} payload
 *
 * @return {Promise}
 */
export const GetUser = payload => axios.get('mobile/user', { payload });

/**
 * Get User languages
 * 
 * @param {Object} payload
 * 
 * @return {Promise}
 */
export const GetLanguages = payload => axios.get('mobile/user/languages', { payload });