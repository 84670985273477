import * as FeedbackApi from '@/api/feedbackApi';

const freshState = () => ({
    profile: null,
    authToken: null
});

const getters = {
    //
};

const mutations = {
    //
};

const actions = {
    submitFeedback(_, form) {
        return FeedbackApi.Create(form);
    }
};

export default {
    namespaced: true,
    state: freshState(),
    getters,
    mutations,
    actions
};
