import * as LanguageApi from '@/api/languageApi';

const freshState = () => ({
    allLanguages: {}
});

const getters = {
    //
};

const mutations = {
    /**
     * Sets all languages
     * 
     * @param {Object} state
     * @param {Object} languages
     */
    SET_LANGUAGES(state, languages) {
        state.allLanguages = languages;
    }
};

const actions = {
    /**
     * Fetches all languages
     * 
     * @return {Promise}
     */
    async fetchLanguages({ commit }) {
        const response = await LanguageApi.FetchLanguages();
        const languages = response.data.reduce((acc, language) => {
            acc[language.code] = language.name;
            return acc;
        }, {});
        commit('SET_LANGUAGES', languages);
        return languages;
    }
};

export default {
    namespaced: true,
    state: freshState(),
    getters,
    mutations,
    actions
};
