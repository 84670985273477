import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';
import { App as CapacitorApp } from '@capacitor/app';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

import 'swiper/css';

/* Theme variables */
import './theme/variables.scss';

/* Tailwind css */
import './theme/tailwind.scss';

import { store } from '@/store';
import axios from 'axios';

// PWA Elements
import { defineCustomElements } from '@ionic/pwa-elements/loader';
defineCustomElements(window);

global.Buffer = global.Buffer || require('buffer').Buffer;

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env['VUE_APP_API_BASE_URL'];
axios.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';
window.axios = axios;

import Big from 'big.js';
Number.prototype.toFixed = function (fractionDigits=1) {
    return new Big(this).toFixed(fractionDigits);
};

const app = createApp(App)
  .use(IonicVue)
  .use(router);

app.use(store);

import FormattingMixin from '@/mixins/FormattingMixin';
app.mixin(FormattingMixin);

import StyledInput from './components/inputs/StyledInput.vue';
app.component('StyledInput', StyledInput);

import BaseModal from './modals/BaseModal.vue';
app.component('BaseModal', BaseModal);

// Add a listener for the app URL open event
CapacitorApp.addListener('appUrlOpen', (data) => {
  const slug = data.url.split('.com').pop();
  if (slug) {
    router.push(slug);
  }
});

CapacitorApp.addListener('appUrlOpen', (data) => {
  if (data.url) {
    const url = new URL(data.url);
    const path = url.pathname;

    // Use your router to navigate to the path
    this.$router.push(path);
  }
});

router.isReady().then(() => {
  app.mount('#app');
});

const routesWithoutLogin = ['Login', 'Register', 'ResetPassword', 'MarkerView'];
router.beforeEach(async (to) => {
  if (store.state.user.profile) {
    if (routesWithoutLogin.includes(to.name)) {
      return { name: 'Explore' };
    }
    return true;
  }

  const storage = new Storage();
  await storage.create();
  if (await storage.get('auth')) {
    try {
      await store.dispatch('user/getUser');
      await store.dispatch('map/findMarker', to.params);
      await store.dispatch('language/fetchLanguages');
      router.replace('tabs/map');
      return true;
    } catch (e) {
      await store.dispatch('user/setAuthToken', null);
      console.error(e);
    }
  }
  if (routesWithoutLogin.includes(to.name)) {
    return true;
  }
  return { name: 'Login' };
});

// Integreate google map api
const googleMapApiKey = process.env['VUE_APP_GOOGLE_MAP_API'];

const googleMapScript = document.createElement('script');
googleMapScript.setAttribute('src', `https://maps.googleapis.com/maps/api/js?key=${googleMapApiKey}&callback=initMapCallback&libraries=geometry,places,marker`)
googleMapScript.setAttribute('defer', '');
googleMapScript.setAttribute('async', '');
document.head.appendChild(googleMapScript);

import { Geolocation } from '@capacitor/geolocation';
import { Storage } from '@ionic/storage';
window.initMapCallback = async () => {
  const storage = new Storage();
  await storage.create();
  store.dispatch('map/setInitGoogleMap', true);
  const prevCoordinate = await storage.get('prevCoordinate');
  if (prevCoordinate) {
    store.dispatch('map/setCurrentLocation', prevCoordinate);
  }
  Geolocation.getCurrentPosition().then(async coordinates => {
    store.dispatch('map/setCurrentLocation', coordinates);
    await Geolocation.watchPosition({ enableHighAccuracy: true }, coordinates => {
      store.dispatch('map/setCurrentLocation', coordinates);
    });
  });
};
