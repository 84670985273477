import { createRouter, createWebHistory } from '@ionic/vue-router';
import TabsPage from '../views/TabsPage.vue';

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    component: () => import('@/views/LoginPage.vue'),
    name: 'Login'
  },
  {
    path: '/register',
    component: () => import('@/views/RegisterPage.vue'),
    name: 'Register'
  },
  {
    path: '/reset-password',
    component: () => import('@/views/ResetPasswordPage.vue'),
    name: 'ResetPassword'
  },
  {
    path: '/marker/:hash',
    component: () => import('@/views/MarkerViewPage.vue'),
    name: 'MarkerView'
  },
  {
    path: '/tabs/',
    component: TabsPage,
    children: [
      {
        path: '',
        redirect: '/tabs/map'
      },
      {
        path: 'map/:hash?',
        component: () => import('@/views/MapPage.vue'),
        name: 'Explore'
      },
      {
        path: 'search-markers',
        component: () => import('@/views/SearchMarkersPage.vue'),
        name: 'SearchMarkers'
      },
      {
        path: 'create-marker',
        component: () => import('@/views/CreateMarkerPage.vue'),
        name: 'CreateMarker'
      },
      {
        path: 'my-markers',
        component: () => import('@/views/MyMarkersPage.vue'),
        name: 'MyMarkers'
      },
      {
        path: 'edit-marker/:hash',
        component: () => import('@/views/EditMarkerPage.vue'),
        name: 'EditMarker'
      },
      {
        path: 'qrcode-marker/:hash',
        component: () => import('@/views/QRCodeMarkerPage.vue'),
        name: 'QRCodeMarker'
      },
      {
        path: 'profile',
        component: () => import('@/views/ProfilePage.vue'),
        name: 'Profile'
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
