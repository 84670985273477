<template>
<ion-modal 
    :is-open="isOpen" 
    :backdrop-dismiss="backgroundClose"
    ref="modal"
    @didDismiss="dismiss">
    <div class="wrapper">
        <div class="absolute right-0 -top-4 z-100">
            <ion-icon 
                id="closeButton" 
                :icon="closeOutline" 
                class="w-8 h-8 bg-black text-white rounded-full border border-white"
                @click="dismiss" />
        </div>
        <slot />
    </div>
</ion-modal>
</template>

<script>
import {
    IonModal,
    IonIcon
} from '@ionic/vue';
import { personCircle, closeOutline } from 'ionicons/icons';
export default {
    components: {
        IonModal,
        IonIcon
    },

    props: {
        isOpen: {
            type: Boolean,
            default: false
        },

        title: {
            type: String,
            default: ''
        },

        backgroundClose: {
            type: Boolean,
            default: true
        }
    },

    methods: {
        dismiss() {
            this.$refs.modal.$el.dismiss();
            this.$emit('onCloseModal');
        }
    },

    setup() {
      return { personCircle, closeOutline };
    }
}
</script>

<style lang="scss" scoped>
ion-modal {
    --width: fit-content;
    --min-width: 100vw;
    --max-width: 100vw;
    --height: fit-content;
    --border-radius: 6px;
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
    --overflow: visible;
}

ion-modal h1 {
    margin: 20px 20px 10px 20px;
}

ion-modal .wrapper {
    margin-bottom: 0px;
}

.z-100 {
    z-index: 100;
}
</style>