import { format, parseISO, startOfDay, fromUnixTime, add, parse } from 'date-fns';

export default {
    methods: {
        formatUnixDate(date, formatString = 'MM/dd/yyyy') {
            return format(fromUnixTime(date), formatString);
        },

        formatUnixDateTime(date, formatString = 'MM/dd/yyyy h:mm a') {
            return format(fromUnixTime(date), formatString);
        },

        formatDate(date, formatString = 'MM/dd/yyyy') {
            return format(parseISO(date + 'Z'), formatString);
        },

        formatDateLocal(date, formatString = 'MM/dd/yyyy') {
            return format(parseISO(date), formatString);
        },

        formatDateTime(date, formatString = 'MM/dd/yyyy h:mm a') {
            return format(parseISO(date), formatString);
        },

        formatDateTimeLocal(date, formatString = 'MM/dd/yyyy h:mm a') {
            return format(parseISO(date), formatString);
        },

        formatTime(time, formatString = 'HH:mm') {
            const date = format(new Date(), 'yyyy-MM-dd');
            return format(`${date} ${time}`, formatString);
        },

        getDateOnly(date) {
            return format(startOfDay(date), 'yyyy-MM-dd');
        },

        getTimeOnly(time) {
            return format(time, 'HH:mm:ss');
        },

        getTodayDate() {
            return format(startOfDay(new Date()), 'yyyy-MM-dd');
        },

        parseDate(date) {
            return parseISO(date);
        },

        parseDateField(date, format = 'yyyy-MM-dd') {
            return parse(date, format, new Date);
        },

        formatDateField(date, formatString = 'MM/dd/yyyy') {
            return format(this.parseDateField(date), formatString);
        },

        addMonths(date, months) {
            return add(date, { months: months });
        },

        addWeeks(date, weeks) {
            return add(date, { weeks: weeks });
        },

        addDays(date, days) {
            return add(date, { days: days });
        },

        formatDecimal(number = 0) {
            return this.Big(number).toFixed(2);
        },

        formatPrice(number = 0) {
            //Add commas
            return this.Big(number).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },

        formatPriceToNumber(number = 0) {
            return this.Big(this.Big(number).toFixed(2)).toNumber();
        },

        getDateStringOnly(date) {
            return format(date, 'MM/dd/yyyy');
        },

        getMonthYear(date) {
            return format(date, 'MMM yy');
        },

        formatPhoneNumber(phoneNumberString) {
            var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
            var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
            if (match) {
                return match[1] + '-' + match[2] + '-' + match[3];
            }
            return null;
        }
    }
};
