import options from './app/options';

/**
 * The default state contains all the 'skeleton' of the app.
 * by using a function instead of a plain object, we can easily 'reset'
 * the module to its initial state at any point.
 *
 * @return {Object}
 */
const getDefaultState = () => ({
    version: {
        hash: process.env.GIT_HASH,
        version: process.env.APP_VERSION,
    },
    count: 0,
    company_name: null,
    user: null
});

/**
 * Initialize starting state
 *
 * https://vuex.vuejs.org/api/#state
 */
const state = getDefaultState();

/**
 * Think of getters as 'global' computed properties. If the
 * objective is to simply use state values, then access them using
 * this.$store.state instead of creating a dummy getter.
 *
 * https://vuex.vuejs.org/api/#getters
 *
 * e.g.
 *  // bad
 *  numbers: state => state.numbers
 *
 * // good
 * evenNumbers: state => state.numbers.filter(n => n % 2 === 0)
 */
const getters = {
    //
};

/**
 * Mutations are simple synchronous ways to update state.
 * this means no Promises or Axios calls. Mutations are often best
 * if they are left as simple as possible
 *
 * https://vuex.vuejs.org/api/#modules
 */
const mutations = {
    /**
     * Resets the state to its factory settings
     *
     * @param {Object} state
     */
    RESET_APP(state) {
        Object.assign(state, getDefaultState());
    }
};

/**
 * Actions are the goto for doing Vuex type things.
 * All API calls for example, are to be done within actions.
 * Actions should attempt to always return a Promise, so that
 * Chaining & composing different actions will be as easy as possible
 *
 * https://vuex.vuejs.org/api/#actions
 */
const actions = {
    /**
     * Resets the app to its default state
     *
     * @param {Object} context
     *
     * @param {Promise}
     */
    async reset({ commit }) {
        commit('RESET_APP');
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
    modules: {
        options
    }
};
