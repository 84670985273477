<template>
  <div>
    <div v-if="showPopup" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div class="bg-black p-6 rounded-lg shadow-lg text-center max-w-sm w-full">
        <img :src="logo" alt="Logo" class="mb-4 w-20 mx-auto">
        <h2 class="text-lg font-semibold mb-2">Open in App</h2>
        <p class="text-white mb-4">For a better experience, open in the app.</p>
        <button @click="handleOpenApp" class="w-full bg-primary text-white py-2 px-4 rounded-lg mb-2 hover:bg-primary-dark">Open App</button>
        <button @click="handleDownloadApp" class="w-full bg-primary text-white py-2 px-4 rounded-lg mb-2 hover:bg-primary-dark">Download App</button>
        <button @click="handleContinueInBrowser" class="w-full bg-gray-300 text-black py-2 px-4 rounded-lg hover:bg-gray-400">Continue in Browser</button>
      </div>
    </div>
  </div>
</template>

<script>
import { isPlatform } from '@ionic/vue';
import logo from '../../public/assets/image/logo.png';

export default {
  data() {
    return {
      showPopup: false,
      logo
    };
  },
  mounted() {
    // Check if the current URL matches the universal link
    if (window.location.hostname === 'dijuno-mobile.pderasapps.com' && this.isMobile && !this.isExcludedPath) {
      this.showPopup = true;
    }
  },
  computed: {
    isMobile() {
      return isPlatform('mobile');
    },
    isIOS() {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    },
    isExcludedPath() {
      const path = window.location.pathname;
      const mapPathPattern = /^\/marker\/[a-f0-9]{64}$/;
      return mapPathPattern.test(path);
    }
  },
  methods: {
    handleOpenApp() {
      this.openApp();
      this.showPopup = false;
    },
    handleContinueInBrowser() {
      this.showPopup = false;
    },
    openApp() {
      const appSchemeUrl = 'dijuno://open';
      window.location.href = appSchemeUrl;
    },
    handleDownloadApp() {
      const appStoreUrl = 'https://apps.apple.com/ca/app/dijuno/id6445800686';
      const playStoreUrl = 'https://play.google.com/store/apps/details?id=com.dijuno.app';

      window.location.href = this.isIOS ? appStoreUrl : playStoreUrl;
    }
  }
}
</script>