<template>
    <BaseModal 
        v-bind="$props" 
        :title="'Marker Detail'" 
        :background-close="false" 
        :is-white="true" 
        @onCloseModal="onCloseModal">
        <div class="py-3 shadow-xl space-y-3 px-3 border border-white rounded bg-gray-800">
            <div class="text-xl flex items-center space-x-2 border-b pb-2">
                <ion-icon class="transform scale-125 text-white" :src="thumbsUp" />
                <div class="text-white">Feedback</div>
            </div>
            <div class="text-white">Please write feedback and submit.</div>
            <StyledInput 
                v-model="form.content" 
                type="textarea" 
                class="text-white"
                :rows="5" 
                placeholder="Feedback" />
            <div class="flex justify-end">
                <ion-button :disabled="loading || !canSubmit" @click="submit">
                    <div class="flex items-center space-x-2">
                        <div>Submit</div>
                        <ion-spinner v-if="loading" name="dots" class="text-center text-white" />
                    </div>
                </ion-button>
            </div>
        </div>
    </BaseModal>
</template>

<script>
import { thumbsUp } from 'ionicons/icons';
import { IonButton, IonIcon, IonSpinner, toastController } from '@ionic/vue';
import { mapActions } from 'vuex';

export default {
    components: {
        IonButton,
        IonIcon,
        IonSpinner
    },

    data: () => ({
        loading: false,
        form: {
            content: ''
        }
    }),

    setup() {
      return { thumbsUp };
    },

    computed: {
        canSubmit() {
            return this.form.content;
        }
    },

    methods: {
        ...mapActions('feedback', ['submitFeedback']),

        submit() {
            this.loading = true;
            this.submitFeedback(this.form).then(async () => {
                const toast = await toastController.create({
                    message: "We have received your feedback. Thank you!",
                    color: 'success',
                    duration: 2500,
                    position: 'bottom'
                });

                await toast.present();
                this.$emit('onCloseModal');
            }).catch(async (e) => {
                console.error(e);
                const toast = await toastController.create({
                    message: "An error occured. Please try again later.",
                    color: 'warning',
                    duration: 2500,
                    position: 'bottom'
                });

                await toast.present();
            }).finally(() => {
                this.loading = false;
            });
        },

        onCloseModal() {
            this.form.content = '';
        }
    }
};
</script>

<style>

</style>