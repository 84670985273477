const getDefaultState = () => ({
    languages: {}
});

const state = getDefaultState();

const getters = {
    getLanguages(state) {
        return state.languages;
    }
};

const mutations = {
    /**
     * Resets the state to its factory settings
     *
     * @param {Object} state
     */
    RESET_APP(state) {
        Object.assign(state, getDefaultState());
    },

    /**
     * Resets the state's property to factory setting
     *
     * @param {Object} state
     * @param {String} key
     *
     */
    RESET_PROP(state, key) {
        Object.assign(state,
            {
                [key]: getDefaultState()[key]
            });
    },
};

const actions = {
    /**
     * Resets the app to its default state
     *
     * @param {Object} context
     * @param {String} key
     *
     * @param {Promise}
     */
    async reset({ commit }, key = null) {
        //check if key exists in state.
        if (key in state) {
            if (key) {
                commit('RESET_PROP', key);
            } else {
                commit('RESET_APP');
            }
        } else {
            console.warn(`[app/options] state does not have ${key} property. Check that the argument are you passing to reset exists.`);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
