<template>
    <div class="relative">
        <ion-input 
            v-if="type === 'input'"
            :value="modelValue" 
            :type="inputType"
            :placeholder="placeholder" 
            :inputmode="inputmode"
            :enterkeyhint="enterkeyhint ? enterkeyhint : 'done'"
            class="input"
            :maxlength="maxlength"
            @ionInput="onInput" />
        <ion-textarea 
            v-else-if="type === 'textarea'"
            :value="modelValue" 
            :type="inputType"
            :rows="rows"
            :inputmode="inputmode"
            :enterkeyhint="enterkeyhint ? enterkeyhint : 'enter'"
            class="input" 
            :maxlength="maxlength"
            :placeholder="placeholder"
            @ionInput="onInput" />
        <div v-if="maxlength > 0" class="absolute right-0 bottom-0 mr-1 mb-1 z-10 bg-gray-500-transparent rounded p-0.5">
            <p class="text-xs text-white text-right">{{ modelValue?.length ?? 0 }} / {{ maxlength }}</p>
        </div>
    </div>
</template>

<script>
import { IonInput, IonTextarea } from '@ionic/vue';

export default {
    components: {
        IonInput,
        IonTextarea
    },

    props: {
        type: {
            type: String,
            default: 'input'
        },

        inputType: {
            type: String,
            default: 'input'
        },

        modelValue: {
            type: String,
            default: ''
        },

        placeholder: {
            type: String,
            default: ''
        },

        rows: {
            type: Number,
            default: 3
        },

        enterkeyhint: {
            type: String,
            default: null
        },

        inputmode: {
            type: String,
            default: null
        },

        maxlength: {
            type: Number,
            default: undefined
        }
    },

    methods: {
        onInput(event) {
            this.$emit('update:modelValue', event.target.value);
        }
    }
};
</script>

<style lang="scss" scoped>
ion-select,
ion-textarea,
ion-input {
  &.input {
    @apply border border-gray-400 shadow-lg;
    
    --padding-start: 0.5rem;
    --padding-end: 0.5rem;
  }
}

.bg-gray-500-transparent {
    background-color: rgb(107 114 128 / 0.8);
}
</style>