<template>
<ion-page>
  <ion-menu content-id="main-content" :swipe-gesture="!isExplorePage">
    <ion-header>
      <ion-toolbar>
        <ion-title>Menu</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-item 
        v-for="(menuItem, index) in menuItems" 
        :key="index" 
        button 
        :color="menuItem.color"
        lines="full"
        @click="onClickMenuItem(menuItem)">
        <ion-label>
          {{ menuItem.label }}
        </ion-label>
        <ion-icon :src="menuItem.icon" slot="end" />
      </ion-item>
    </ion-content>
  </ion-menu>
  <ion-page id="main-content">
    <ion-tabs class="bg-primary" ref="tabsRef">
      <ion-router-outlet id="main-content"></ion-router-outlet>
      <ion-tab-bar ref="tab-bar" class="grid grid-cols-6 bg-primary">
        <ion-tab-button 
          tab="map" 
          class="col-span-2 flex justify-between border-r max-w-none bg-primary text-white ios tab-has-label tab-has-label-only tab-layout-icon-top ion-activatable ion-selectable ion-focusable"
          :class="isExplorePage && !isInARMode ? 'tab-selected' : ''"
          @click="onClickExploreButton">
          <ion-label class="pt-1">Explore</ion-label>
          <ion-img src="/assets/icon/world_icon.png" class="h-9 w-16 absolute -bottom-2" />
        </ion-tab-button>

        <ion-tab-button 
          tab="my-markers" 
          href="/tabs/create-marker" 
          class="col-span-2 flex justify-between border-r max-w-none bg-primary text-white">
          <ion-label class="pt-1">Leave Your Mark</ion-label>
          <ion-img src="/assets/icon/map_marker_icon.png" class="h-9 absolute -bottom-2 w-16" />
        </ion-tab-button>

        <ion-tab-button 
          tab="profile" 
          class="col-span-2 flex justify-between border-r max-w-none bg-primary text-white"
          @click="openMenu">
          <ion-label class="pt-1 capitalize">{{ profile.username }}</ion-label>
          <div class="h-9 absolute -bottom-2 w-12 bg-white rounded-t-full overflow-hidden">
            <ion-img :src="profile.getAvatarUrl()" :class="{'scale-75 w-full h-full': !profile.avatar}" />
          </div>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
  <FeedbackModal 
        :is-open="showFeedbackModal" 
        @onCloseModal="showFeedbackModal = false" />
</ion-page>
</template>

<script>
import { 
  IonTabBar, IonTabButton, IonTabs, IonLabel, IonPage, IonRouterOutlet, IonImg, 
  IonMenu, IonHeader, IonToolbar, IonTitle, IonContent, IonIcon, IonItem, menuController
} from '@ionic/vue';
import { 
  settingsOutline, earth, map, pin, personCircle, settings, informationCircle,
  logOut, thumbsUp, listOutline
} from 'ionicons/icons';
import { mapActions, mapGetters, mapState } from 'vuex';
import { Browser } from '@capacitor/browser';
import FeedbackModal from '../modals/FeedbackModal.vue';

export default {
  components: {
    IonTabBar,
    IonTabButton,
    IonTabs,
    IonLabel,
    IonPage,
    IonRouterOutlet,
    IonImg,
    IonMenu,
    IonHeader,
    IonToolbar, 
    IonTitle,
    IonContent,
    IonIcon,
    IonItem,
    FeedbackModal
  },

  data() {
    return {
        showFeedbackModal: false
    };
  },

  setup() {
    return { settingsOutline, earth, pin, map, personCircle, settings, informationCircle, logOut };
  },

  computed: {
    ...mapGetters('user', {
      profile: 'getUser'
    }),

    ...mapState('map', ['isInARMode']),

    activeTab() {
      return this.$refs['tab-bar']?.tabState?.activeTab ?? null;
    },

    menuItems() {
      return [
        { label: 'Explore', icon: earth, page: 'Explore' },
        { label: 'Leave Your Mark', icon: '/assets/icon/markers/map_marker.svg', page: 'CreateMarker' },
        { label: 'My Marks', icon: listOutline, push: true, page: 'MyMarkers' },
        { label: 'Favourites', icon: map, action: 
            () => {
                this.$router.replace({ name: 'SearchMarkers', query: { favourite: true } });
            }
        },
        { label: 'Profile', icon: personCircle, push: true, page: 'Profile' },
        {
          label: 'Feedback', 
          icon: thumbsUp, 
          action: async () => {
            this.showFeedbackModal = true;
          }
        },
        {
          label: 'About', 
          icon: informationCircle, 
          action: async () => {
            await Browser.open({ url: 'https://dijuno.ca/' });
          }
        },
        {
          label: 'Logout', 
          icon: logOut, 
          color: 'danger',
          action: () => {
            this.logout().finally(() => {
              this.$router.replace({ name: 'Login' });
            });
          }
        }
      ];
    },

    isExplorePage() {
      return this.$route.name == 'Explore';
    }
  },

  methods: {
    ...mapActions('user', ['logout']),

    ...mapActions('map', ['setIsInARMode']),

    openMenu() {
      menuController.open();
    },

    onClickMenuItem(menuItem) {
      menuController.close();
      if (menuItem.page) {
        const verb = menuItem.push ? 'push' : 'replace';
        this.$router[verb]({ name: menuItem.page });
      } else if (menuItem.action) {
        menuItem.action();
      }
    },

    onClickExploreButton() {
        if (!this.isExplorePage) {
            this.setIsInARMode(false);
            this.$router.replace('/tabs/map');
        } else if (this.isInARMode) {
            this.setIsInARMode(false);
        }
    }
  }
}
</script>

<style lang="scss" scoped>
ion-tab-button {
  &.tab-selected {
    @apply bg-primary-dark;
  }
}

.avatar {

}

.tab-button {
  --padding-start: 0px !important;
  --padding-end: 0px !important;

  .tab-img {
    position: absolute;
    bottom: 0;
    height: 1.5rem;
    width: auto;
    object-fit: contain;
  }

  &.avatar-tab-button {
    grid-column: span 2 / span 2;

    .tab-label {
      left: 1rem;
    }
  }
}

.no-padding-start {
  --padding-start: 0px !important;
}

.no-padding-end {
  --padding-end: 0px !important;
}

:host {
  --padding-start: 0px;
  --padding-end: 0px;

  --color-selected: #aef1ed;
  --ion-tab-bar-color-selected: #aef1ed;
}

#tab-button-tab3 {
  overflow: visible !important;

  a {
    overflow: visible !important;
  }
}

.button-native {
  overflow: visible !important;
}

ion-menu::part(container) {
  box-shadow: 4px 0px 16px rgba(30, 30, 30, 0.5);
}

</style>