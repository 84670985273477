import axios from 'axios';

/**
 * Get clips
 *
 * @param {Object} params
 *
 * @return {Promise}
 */
export const GetMarkers = params => axios.get('mobile/clips', { params });

/**
 * Get a clip
 *
 * @param {Object} payload
 *
 * @return {Promise}
 */
export const GetMarker = payload => axios.get(`mobile/clips/${payload.hash}`);

/**
 * Get clips categories
 *
 * @param {Object} params
 *
 * @return {Promise}
 */
export const GetClipCategories = params => axios.get('mobile/clips/categories', { params });

/**
 * Get clip's audio
 *
 * @param {String} hash
 * @param {Object} params
 *
 * @return {Promise}
 */
export const GetClipAudio = (hash, params) => axios.get(`mobile/clips/${hash}/audio`, { responseType: 'arraybuffer', params });

/**
 * Listen clip's audio
 *
 * @param {String} hash
 * @param {Object} payload
 *
 * @return {Promise}
 */
export const ListenClipAudio = (hash, payload) => axios.post(`mobile/clips/${hash}/audio/play`, { payload });

/**
 * Flag a marker
 *
 * @param {Object} payload
 *
 * @return {Promise}
 */
export const FlagMarker = (payload) => axios.post(`mobile/clips/${payload.hash}/flag`, payload);

/**
 * Delete a marker
 *
 * @param {String} hash
 *
 * @return {Promise}
 */
export const DeleteMarker = (hash) => axios.delete(`mobile/clips/${hash}`);

/**
 * Get clip's photo
 *
 * @param {String} clipHash
 * @param {String} photoHash
 * @param {Object} params
 *
 * @return {Promise}
 */
export const GetClipPhoto = (clipHash, photoHash, params) => axios.get(`mobile/clips/${clipHash}/photos/${photoHash}`, { responseType: 'arraybuffer', params });

/**
 * Favorite a clip
 *
 * @param {Object} params
 *
 * @return {Promise}
 */
export const UserFavoriteClip = (payload) => axios.post(`mobile/clips/${payload.hash}/favorite`);

/**
 * Find a marker
 * 
 * @param {Object} payload
 * 
 * @return {Promise}
 */
export const findMarker = (payload) => axios.get(`mobile/clips/${payload.hash}/find`);

/**
 * Find a marker by hash
 * 
 * @param {String} hash
 * 
 * @return {Promise}
 */
export const findMarkerByHash = (hash) => axios.get(`mobile/clips/${hash}/qr/find`);

/**
 * Create a clip
 *
 * @param {Object} payload
 * @param {Array} photos
 *
 * @return {Promise}
 */
export const CreateClip = (payload, photos) => {
    const formData = new FormData();
    formData.append('audio', payload.audio.blob);
    formData.append('audioDuration', payload.audio.duration);
    formData.append('lat', payload.latLng.lat);
    formData.append('lng', payload.latLng.lng);
    formData.append('category[id]', payload.category.id);
    formData.append('title', payload.title);
    formData.append('location', payload.location);
    formData.append('description', payload.description);
    formData.append('status', payload.clip_status.value);
    payload.languages.forEach(language => {
        formData.append('languages[]', language);
    });
    let index = 0;
    for (const photo of photos) {
        formData.append(`photos[${index}]`, photo.blob);
        index ++;
    }
    return axios.post('mobile/clips', formData);
};

/**
 * Update a clip
 *
 * @param {Object} payload
 * @param {Array} photos
 *
 * @return {Promise}
 */
export const UpdateClip = (payload, photos) => {
    const formData = new FormData();
    formData.append('audio', payload.audio.blob);
    formData.append('audioDuration', payload.audio.duration);
    formData.append('lat', payload.latLng.lat);
    formData.append('lng', payload.latLng.lng);
    formData.append('category[id]', payload.category.id);
    formData.append('title', payload.title);
    formData.append('location', payload.location);
    formData.append('description', payload.description);
    formData.append('status', payload.clip_status.value);
    let index = 0;
    for (const photo of photos) {
        formData.append(`photos[${index}]`, photo.blob);
        index ++;
    }
    return axios.post(`mobile/clips/${payload.hash}`, formData);
};