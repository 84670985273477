<template>
  <ion-app>
    <AppRedirect />
    <ion-router-outlet/>
  </ion-app>
</template>

<script setup>
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { ScreenOrientation } from '@capacitor/screen-orientation';
import { Keyboard } from '@capacitor/keyboard';
import AppRedirect from '@/components/AppRedirect.vue';

Keyboard.setResizeMode({ mode: 'body' });
ScreenOrientation.lock({ 'orientation': 'portrait' });
</script>