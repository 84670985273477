import { createStore } from 'vuex'
import { vuexLoader } from '@/utils/vueUtils';

export const store = createStore({
    // Strict mode: don't mutate state outside of mutations
    strict: process.env.NODE_ENV !== 'production',

    // automatically import all files in modules folder as vuex modules 🧙✨
    modules: vuexLoader(require.context('./modules', false, /\.js$/i))
});
