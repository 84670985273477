import * as UserApi from '@/api/userApi';
import { Storage } from '@ionic/storage';

const freshState = () => ({
    profile: null,
    authToken: null,
    languages: []
});

const getters = {
    getUser(state) {
        return {
            ...state.profile,
            getAvatarUrl: () => state.profile?.avatar ?? '/assets/image/default-avatar.png'
        };
    }
};

const mutations = {
    SET_PROFILE(state, user) {
        state.profile = user;
    },

    SET_AVATAR(state, avatar) {
        state.profile.avatar = avatar;
    },

    SET_LANGUAGES(state, languages) {
        state.languages = languages;
    },

    SET_AUTH_TOKEN(state, authToken) {
        state.authToken = authToken;
    }
};

const actions = {
    login({ dispatch }, payload) {
        return UserApi.Login(payload).then(async (response) => {
            await dispatch('saveAuthToken', response);
            return dispatch('getUser');
        });
    },

    register({ dispatch }, payload) {
        return UserApi.Register(payload).then(async (response) => {
            await dispatch('saveAuthToken', response);
            return dispatch('getUser');
        });
    },

    getLanguages({ commit }, languages) {
        return UserApi.GetLanguages(languages).then((response) => {
            commit('SET_LANGUAGES', response.data.languages);
        });
    },

    async saveAuthToken(_, response) {
        const data = response.data;
        const authToken = data.authorization.token;
        const authType = data.authorization.type;
        const authorization = `${authType} ${authToken}`;
        const storage = new Storage();
        await storage.create();
        await storage.set('auth', authorization);

        window.axios.defaults.headers.common['Authorization'] = authorization;
    },

    delete({ commit }, payload) {
        return UserApi.Delete(payload).then(() => {
            commit('SET_PROFILE', null);
        });
    },

    logout({ commit }, payload) {
        return UserApi.Logout(payload).then(() => {
            commit('SET_PROFILE', null);
        });
    },

    update(_, payload) {
        return UserApi.Update(payload);
    },

    updateAvatar(_, payload) {
        return UserApi.UpdateAvatar(payload);
    },

    async getAvatar(_, payload) {
        const hash = payload.hash;
        const params = payload?.params ?? {};
        return UserApi.GetAvatar(hash, params)
            .then((response) => Buffer.from(response.data, 'binary').toString('base64'));
    },

    async getUser({ dispatch, commit, state }, payload) {
        if (!state.authToken) {
            const store = new Storage();
            await store.create();
            let authToken = await store.get('auth');
            if (authToken) {
                if (typeof authToken === 'object') {
                    authToken = authToken['value'];
                }
                if (authToken) {
                    commit('SET_AUTH_TOKEN', authToken);
                }
                window.axios.defaults.headers.common['Authorization'] = authToken;
            }
        }
        return UserApi.GetUser(payload).then(async response => {
            const user = response.data.user;
            if (user.profile_picture?.hash) {
                const avatarBase64 = await dispatch('getAvatar', { hash: user.profile_picture.hash });
                const base64Str = `data:${user.profile_picture.mime_type};base64,${avatarBase64}`;
                user.avatar = base64Str;
            }
            commit('SET_PROFILE', user);
            return user;
        });
    },

    setUser({ commit }, user) {
        commit('SET_PROFILE', user);
    },

    async setAuthToken({ commit }, authToken) {
        const store = new Storage();
        await store.create();
        await store.set('auth', authToken);
        commit('SET_AUTH_TOKEN', authToken);
    }
};

export default {
    namespaced: true,
    state: freshState(),
    getters,
    mutations,
    actions
};
